import logo from './logo.svg';
import './App.css';
import React, {useRef, useEffect, useState} from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken = 'pk.eyJ1IjoiYmttemMiLCJhIjoiY2phMnppNmVvM2dkMzJ4cGM3bHVjb2ZqZSJ9.M2D4VykfkM_Vffmx0Qp8oA'

function App() {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(69.404970);
    const [lat, setLat] = useState(53.280556);
    const [zoom, setZoom] = useState(9);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: '/style.json',
            center: [lng, lat],
            zoom: zoom
        });
    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        console.log('12313')
        console.log(map.current)

        map.current.on('load', () => {
            map.current.loadImage('/icons/wave.png', (error, image) => {
                if (error) throw error;
                map.current.addImage('wave', image);
            });
            map.current.loadImage('/icons/oneway.png', (error, image) => {
                if (error) throw error;
                map.current.addImage('oneway', image);
            });

            addSources({map: map.current})
            addLayers({map: map.current})

        });

        map.current.on('click', e => {
            const bbox = [
                [e.point.x - 5, e.point.y - 5],
                [e.point.x + 5, e.point.y + 5]
            ];
            const selectedFeatures = map.current.queryRenderedFeatures(bbox);
            const selectedLayers = selectedFeatures.map(f => f.layer.id)
            console.log(selectedLayers)
        })

        map.current.on('click', 'buildingsWithAddr', e => {
            const coordinates = e.features[0].geometry.coordinates.slice();
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
            const passportId = e.features[0].properties.PassportId
            const descr = passportId ?
                `<a target="_blank" href="https://kokshe.eregion.kz/Passportization/WebClient/#/house/view/${e.features[0].properties.PassportId}">Паспорт объекта</a>`
                : 'Нет паспорта'
            new mapboxgl.Popup()
                .setLngLat(coordinates[0][0])
                .setHTML(descr)
                .addTo(map.current);
        })

        map.current.on('mouseenter', 'buildingsWithAddr', () => {
            map.current.getCanvas().style.cursor = 'pointer';
        });
        map.current.on('mouseleave', 'buildingsWithAddr', () => {
            map.current.getCanvas().style.cursor = '';
        });

    });

    return (
        <div ref={mapContainer} className="map-container"/>
    );
}

export const addSources = ({map}) => {
    map.addSource('buildings', {
        type: 'vector',
        scheme: 'tms',
        tiles: ['https://gsv.eregion.kz/geoserver/gwc/service/tms/1.0.0/kokshetau:buildings@EPSG%3A900913@pbf/{z}/{x}/{y}.pbf']
    })
    map.addSource('buildingsWithAddr', {
        type: 'vector',
        scheme: 'tms',
        tiles: ['https://gsv.eregion.kz/geoserver/gwc/service/tms/1.0.0/kokshetau:buildingsWithAddr@EPSG%3A900913@pbf/{z}/{x}/{y}.pbf']
    })
    map.addSource('gasInstallations', {
        type: 'geojson',
        // scheme: 'tms',
        // tiles: ['https://gsv.eregion.kz/geoserver/gwc/service/tms/1.0.0/kokshetau:gasInstallations@EPSG%3A900913@/{z}/{x}/{y}.geojson']
        data: 'https://gsv.eregion.kz/geoserver/kokshetau/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=kokshetau%3AgasInstallations&maxFeatures=500&outputFormat=application%2Fjson'
    })
}

export const addLayers = ({map}) => {
    map.addLayer({
        id: 'buildings',
        type: 'fill',
        source: 'buildings',
        'source-layer': 'buildings',
        paint: {
            'fill-color': '#f97c00',
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 13, 0, 14, 0.4]
        }
    },)
    map.addLayer({
        id: 'buildingsWithAddr',
        type: 'fill',
        source: 'buildingsWithAddr',
        'source-layer': 'buildingsWithAddr',
        minzoom: 13,
        maxzoom: 18,
        paint: {
            'fill-color': '#000',
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 13, 0, 14, 0.4]
        }
    },)
    map.addLayer({
        id: 'buildingsWithAddr_labels',
        type: 'symbol',
        source: 'buildingsWithAddr',
        'source-layer': 'buildingsWithAddr',
        minzoom: 16,
        maxzoom: 18,
        layout: {
            'text-field': ['get', 'name'],
            'text-font': ['Open Sans Regular'],
            'text-size': 12,
            'text-padding': 2,
            'text-allow-overlap': false
        },
        paint: {
            'text-color': '#333',
            'text-halo-color': 'hsl(0, 0%, 100%)',
            'text-halo-width': 1.5,
            'text-halo-blur': 1
        }
    },)
    map.addLayer({
        id: 'gasInstallations',
        type: 'circle',
        source: 'gasInstallations',
        minzoom: 1,
        maxzoom: 18,
        layout: {
            visibility: 'none'
        },
        paint: {
            'circle-radius': ['interpolate', ['linear'], ['zoom'], 12, 2, 14, 6],
            'circle-color': '#e55e5e'
        }
    },)
}

export default App;
